<template lang="html">
  <div class="app-body">
    <regist-component
      @on-success="onLoginSuccess"
      @on-login-click="onLoginClick"></regist-component>
  </div>
</template>

<script>
import RegistComponent from './regist-component.vue';

export default {
  components: {
    RegistComponent,
  },
  methods: {
    // 登录成功后
    onLoginSuccess() {
      const { from } = this.$route.query;
      if (from) {
        window.location.href = from;
      } else {
        this.$router.push({ path: '/' });
      }
    },
    // 点击登录按钮时
    onLoginClick() {
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>
